import NoSleep from 'nosleep.js';
import emailjs from '@emailjs/browser';
import settings from '../../../settings.json';
import simulation from '../../../simulation.json';
import Chart from 'chart.js/auto';

const noSleep = new NoSleep();
const IS_MAC = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
const POINTS_0 = { distance: 0, timestamp: new Date().getTime(), speed: 0 };

export default {
  data() {
    return {
      chart: null,
      convertedResult: [],
      csv: null,
      distanceRest: settings.distance, // meters
      distances: [
        { name: '30m', distance: 30 },
        { name: '200m', distance: 200 },
        { name: '400m', distance: 400 },
        { name: '800m', distance: 800 },
        { name: '1km', distance: 1000 },
      ],
      error: null,
      gpsAccuracyMeter: 100,
      isArmed: false,
      isTestInProgress: false,
      logsHistory: [],
      pos: {},
      previousCoords: {},
      result: [],
      section: 'test',
      settings,
      simulationIntervalID: null,
      simulationPoint: 0,
      sparklineHeight: 50,
      sparklineValues: [],
      sparklineWidth: 100,
      speed: 100,
      speedometerSize: isLandscape() ? 220 : 280,
      testIndex: 1,
    };
  },

  computed: {
    buttonColor() {
      if (this.isTestInProgress) {
        return 'green';
      } else if (this.isArmed) {
        return 'red';
      } else {
        return 'primary';
      }
    },
    buttonTitle() {
      if (this.isTestInProgress) {
        return `Test en cours...${Math.round(this.distanceRest)}m`;
      } else if (this.isArmed) {
        return 'Accelère!';
      } else {
        return 'Démarrer';
      }
    },
    countErrors() {
      return this.logsHistory.filter((it) => it.type === 'error').length;
    },
    gpsAccuracyColor() {
      if (this.gpsAccuracyMeter < 6) {
        return 'green';
      } else if (this.gpsAccuracyMeter < 10) {
        return 'amber';
      } else {
        return 'red';
      }
    },
    roundedSpeed() {
      return Math.round(this.speed);
    },
    vmax() {
      if (this.result.length === 0) {
        return { speed: 0, distance: 0 };
      }

      let point;
      for (const d of this.result) {
        if (!point || d.speed > point.speed) {
          point = d;
        }
      }

      return point;
    },
  },

  async created() {
    navigator.geolocation.watchPosition(this.onGPSChange, this.onGPSError, {
      enableHighAccuracy: true,
      maximumAge: 0,
    });

    document.addEventListener('click', this.enableNoSleep, false);

    window.matchMedia('(orientation: portrait)').addEventListener('change', this.computeElementsSize);
  },

  async mounted() {
    await this.computeElementsSize();
  },

  destroyed() {
    document.removeEventListener('click', this.enableNoSleep, false);
    noSleep.disable();
    this.log(' Mode "NoSleep" désactivé');
  },

  methods: {
    computeDistance(lat1, lon1, lat2, lon2, unit = 'M') {
      if (lat1 == lat2 && lon1 == lon2) {
        return 0;
      } else {
        var radlat1 = (Math.PI * lat1) / 180;
        var radlat2 = (Math.PI * lat2) / 180;
        var theta = lon1 - lon2;
        var radtheta = (Math.PI * theta) / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit == 'K') {
          dist = dist * 1.609344;
        }

        if (unit == 'M') {
          dist = dist * 1.609344 * 1000;
        }

        if (unit == 'N') {
          dist = dist * 0.8684;
        }

        return dist;
      }
    },
    computeFrac(i, points) {
      if (!points?.[0]) {
        this.log('OUPS', JSON.stringify(points), i);
      }

      const extent = points[1].distance - points[0].distance;
      return (i - points[0].distance) / extent;
    },
    async computeElementsSize() {
      await sleep(500);

      if (isLandscape()) {
        this.sparklineHeight = window.innerHeight - 200;
        this.sparklineWidth = window.innerWidth / 1.5;
        this.speedometerSize = 220;
      } else {
        this.sparklineHeight = window.innerHeight - 25 - 280 - 220;
        this.sparklineWidth = window.innerWidth;
        this.speedometerSize = 280;
      }

      this.log(
        `W:${window.innerWidth}, H:${window.innerHeight}, landscape:${isLandscape()}, sparkline: [W:${
          this.sparklineWidth
        }, H:${this.sparklineHeight}]`
      );
    },
    convertJsonToCsv(json, separator = ',') {
      const lines = [
        `Distance [m]${separator}Vitesse [km/h]${separator}Date${separator}Heure${separator}Latitude${separator}Longitude${separator} accuracy`,
      ];
      for (const d of json) {
        const dateTime = new Date(d.timestamp).toLocaleString('fr').split(' ');
        const date = dateTime[0];
        const heure = dateTime[1];
        const distance = d.distance;
        const vitesse = Math.round(d.speed);
        const latitude = d.latitude;
        const longitude = d.longitude;
        const accuracy = d.accuracy;

        lines.push(
          `${distance}${separator}${vitesse}${separator}${date}${separator}${heure}${separator}${latitude}${separator}${longitude}${separator}${accuracy}`
        );
      }

      return lines.join('\r\n');
    },
    convertJsonToStandardPoints(json, distance) {
      const nbPoints = distance + 1;
      const data = new Array(nbPoints);

      for (let i = 0; i < data.length; i++) {
        let points;
        try {
          points = this.findPoints(json, i);
          if (points.length === 1) {
            data[i] = points[0];
            continue;
          } else if (points.length !== 2) {
            throw new Error('Cannot find points to compute interpolation');
          }

          const frac = this.computeFrac(i, points); //
          data[i] = { distance: i, ...this.interpolate(points[0], points[1], frac) };
        } catch (e) {
          this.log(`JSON: ${JSON.stringify(json.slice(0, 4))}`);
          e.message = `Cannot findPoints/computeFrac for i: ${i}: [${JSON.stringify(points)}]: ${e.message}`;
          this.onError(e);
          throw new Error(e);
        }
      }

      return data;
    },
    drawChart() {
      const ctx = document.getElementById('result-chart');
      if (this.chart) {
        this.chart.destroy();
      }

      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.convertedResult.map((i) => i.distance),
          datasets: [
            {
              label: 'Vitesse (km/h) sur distance (m)',
              data: this.convertedResult.map((i) => i.speed),
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
    enableNoSleep() {
      this.log(' Mode "NoSleep" activé');
      noSleep.enable();
    },
    async endTest() {
      this.log('Test terminé');
      try {
        this.convertedResult = this.convertJsonToStandardPoints(this.result, this.settings.distance);
        this.log(`Data converted to ${this.settings.distance} points`);
        this.csv = this.convertJsonToCsv(this.convertedResult);
        this.log(`Data converted to CSV`);
      } catch (e) {
        e.message = `Cannot convert data:${e.message}`;
        this.onError(e);
      }
      this.section = 'result';
      await sleep(500);
      this.sendEmail(this.result);
      this.reset();
    },
    findPoints(json, i) {
      let firstPoint;
      for (const d of json) {
        if (d.distance === i) {
          return [d];
        }

        if (i === 0) {
          return [{ ...d, distance: 0, speed: 0 }];
        }

        if (d.distance < i) {
          firstPoint = d;
        } else if (d.distance > i) {
          return [firstPoint, d];
        }
      }

      return [];
    },
    async goToSheet() {
      if (this.convertedResult.length > 0) {
        const text = this.convertJsonToCsv(this.convertedResult, '	');
        await copyTextToClipboard(text);
      }

      window.open(settings.urlSheet, '_blank');
    },
    interpolate(a, b, frac) {
      const timestamp = a.timestamp + (b.timestamp - a.timestamp) * frac;
      const speed = a.speed + (b.speed - a.speed) * frac;
      const latitude = a.latitude + (b.latitude - a.latitude) * frac;
      const longitude = a.longitude + (b.longitude - a.longitude) * frac;
      const accuracy = a.accuracy + (b.accuracy - a.accuracy) * frac + ' (interpolate)';
      return { timestamp, speed, latitude, longitude, accuracy };
    },
    log(text, error = null) {
      const type = error ? 'error' : 'log';
      const log = { date: new Date().toLocaleString(), type, text };
      this.logsHistory.push(log);
      if (type === 'log') {
        console.log(text);
      } else if (type === 'error') {
        console.error(error);
      }
    },
    onError(error) {
      if (typeof error === 'string') {
        error = new Error(error);
      }

      this.error = error;
      this.log(error.message, error);
    },
    onGPSChange(pos) {
      if (!settings.simulation) {
        this.onPositionChange(pos);
      }
    },
    onGPSError(error) {
      if (!settings.simulation) {
        this.onError(error);
      }
    },
    onPositionChange(pos) {
      this.pos = pos;
      const { coords } = pos;

      const lat1 = coords.latitude;
      const lon1 = coords.longitude;
      const lat2 = this.previousCoords.latitude;
      const lon2 = this.previousCoords.longitude;
      let distanceFromPreviousPoint = !lat2 ? 0 : this.computeDistance(lat1, lon1, lat2, lon2);

      if (this.isArmed && !this.isTestInProgress && distanceFromPreviousPoint > 0.5) {
        this.result[0].distance = 0;
        this.result[0].speed = 0;
        this.isTestInProgress = true;
        this.log('Démarrage des acquisitions...');
      }

      this.speed = coords.speed ? coords.speed * 3.6 : 0;
      this.gpsAccuracyMeter = Math.round(coords.accuracy);
      this.distanceRest = this.distanceRest - distanceFromPreviousPoint;

      const data = {
        distance: this.settings.distance - this.distanceRest,
        timestamp: new Date().getTime(),
        speed: this.speed,
        latitude: coords.latitude,
        longitude: coords.longitude,
        accuracy: coords.accuracy,
      };

      if (this.isTestInProgress) {
        this.sparklineValues.push(this.speed);
        this.result.push(data);

        if (this.distanceRest <= 0) {
          this.endTest();
        }
      } else if (distanceFromPreviousPoint === 0) {
        this.result[0] = data;
      }
      this.previousCoords = coords;
    },
    onSimulationChange() {
      const coords = simulation[this.simulationPoint % simulation.length];
      const point = {
        timestamp: new Date().getTime(),
        coords,
      };
      this.onPositionChange(point);
      this.simulationPoint++;
    },
    reset() {
      this.simulationPoint = 0;
      this.distanceRest = this.settings.distance;
      this.isArmed = false;
      this.sparklineValues = [];
      this.isTestInProgress = false;
    },
    sendEmail() {
      try {
        let title = `Test ${this.testIndex}`;
        this.testIndex++;
        const subject = `[Mob] ${title || ''} (${this.result.length} points sur ${this.settings.distance} m)`;
        const templateData = { to: 'francoisbeaufils@gmail.com', subject, csv: this.csv };

        emailjs.send('service_zfo2wfi', 'template_pb2qshl', templateData, 'k1Vq6V7fE8v4zNhvo').then(
          () => {
            this.log('Email envoyé!');
          },
          (error) => {
            this.onError(error);
          }
        );
      } catch (e) {
        e.message = `Cannot send email: ${e.message}`;
        this.onError(e);
      }
    },
    startNewTest() {
      if (IS_MAC) {
        this.settings.simulation = true;
      }

      this.error = null;
      this.convertedResult = [];
      this.result = [POINTS_0];
      this.sparklineValues = [];
      this.distanceRest = this.settings.distance;
      this.isArmed = true;
      this.log("Armage d'un nouveau test sur " + this.settings.distance + ' mètres');
    },
  },

  watch: {
    async convertedResult(value) {
      if (this.section === 'result' && value.length > 0) {
        await this.$nextTick();
        this.drawChart();
      }
    },
    async section(value) {
      if (value === 'result' && this.convertedResult.length > 0) {
        await this.$nextTick();
        this.drawChart();
      }
    },
    'settings.simulation': {
      handler(isSimulation) {
        this.log('Mode simulation ' + (isSimulation ? 'activé' : 'désactivé'));

        if (isSimulation) {
          this.previousCoords = {};
          this.onSimulationChange();
          this.simulationIntervalID = setInterval(this.onSimulationChange, 2000);
          this.section = 'test';
        } else {
          clearInterval(this.simulationIntervalID);
        }
      },
      immediate: true,
    },
  },
};

async function sleep(duration) {
  return new Promise((resolve) => setTimeout(resolve, duration));
}

function isLandscape() {
  return screen?.orientation?.type ? screen?.orientation?.type === 'landscape-primary' : window.orientation !== 0;
}

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
async function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  await navigator.clipboard.writeText(text).then(
    function () {
      console.log('Async: Copying to clipboard was successful!');
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    }
  );
}
